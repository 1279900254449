@import "fonts";
@import "variables";

html {
  height: 100%;
  width: 100%;
}
body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  color: white;
  background-color: #000;
}
#root {
  min-height: 100%;
  width: 100%;
}

.demo-logo-vertical{
  background-image: url("../../src/assets/images/logogeeko.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  padding: 15px 0;
  text-align: center;
  height: 64px;
}
.ant-layout-sider-collapsed .demo-logo-vertical{
  padding: 15px 0;
  text-align: center;
  height: 64px;
  width: 33px;
  background-position: 0;
  margin: 0 auto;
}
