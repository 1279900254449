@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.ticker_desc {
  font-size: 12px;
  color: $gray_text;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ticker_desc1 {
  font-size: 16px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Roboto-Medium";
}
.col_width {
  width: calc(100% - 125px);
}
.btn_star {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}